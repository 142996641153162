import React from "react"
import { Link } from "gatsby"

import Icon from "../Utilities/Icon"
import styles from "./DetailedStatsPageBlock.module.css"


class DetailedStatsPageBlock extends React.Component {

  render() {

    return (

      <article className={`text-center my-4 sm:my-5 lg:my-6 xl:my-8 sm:w-1/2 lg:w-1/3 px-4 md:px-6 lg:px-4 xl:px-6 ${styles.detailedStatsPageBlock}`}>
        <div>
          <h2 className="uppercase tracking-wider font-bold text-lg 2xl:text-xl">
            {this.props.title} <span className="block text-xs text-gray-600 font-light">{this.props.subTitle}</span>
          </h2>
          <div className="flex mt-3">

            <div className="flex flex-col w-1/2 border-r border-solid border-noir">
              <h3 className="text-xs pt-1 pb-2 tracking-wider bg-noir-lighten10 text-messi uppercase">Messi <span className="sr-only">{this.props.playerTitle}</span></h3>
              <div className={`p-2 bg-messi ${styles.mainStat}`}>
                <span className="inline-block text-3xl 2xl:text-4xl font-bold relative">{this.props.mMainStat} <span className="sr-only">{this.props.playerTitle}</span> {this.props.mainStatWinner === 'm' ? <Icon iconName="icon-star" /> : ''}</span>
              </div>
              <div className={`flex-auto text-xs px-4 py-2 bg-noir-lighten10 relative text-gray-500 ${styles.subStat}`}>
                <p><span className="sr-only">Messi</span> {this.props.mSubStat} {this.props.subStatWinner === 'm' ? <Icon iconName="icon-star" /> : ''}</p>
              </div>
            </div>

            <div className="flex flex-col w-1/2 border-l border-solid border-noir">
              <h3 className="text-xs pt-1 pb-2 tracking-wider bg-noir-lighten10 text-ronaldo uppercase">Ronaldo <span className="sr-only">{this.props.playerTitle}</span></h3>
              <div className={`p-2 bg-ronaldo ${styles.mainStat}`}>
                <span className="inline-block text-3xl 2xl:text-4xl font-bold relative">{this.props.rMainStat} <span className="sr-only">{this.props.playerTitle}</span> {this.props.mainStatWinner === 'r' ? <Icon iconName="icon-star" /> : ''}</span>
              </div>
              <div className={`flex-auto text-xs px-4 py-2 bg-noir-lighten10 relative text-gray-500 ${styles.subStat}`}>
                <p><span className="sr-only">Ronaldo</span> {this.props.rSubStat} {this.props.subStatWinner === 'r' ? <Icon iconName="icon-star" /> : ''}</p>
              </div>
            </div>

          </div>
          <Link to={`${this.props.moreBtnLink}/`} className="inline-block bg-highlight text-noir text-sm mt-4 px-4 py-2 rounded-full">{this.props.moreBtnText}</Link>
        </div>
      </article>

    )
  }
}

export default DetailedStatsPageBlock