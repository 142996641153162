import React from "react"
import { graphql } from "gatsby"
import statWinner from "../js/statWinner"
import formatNumComma from "../js/formatNumComma"

import Layout from "../components/layout"
import SEO from "../components/seo"
import LastUpdated from "../components/LastUpdated/LastUpdated"
import DetailedStatsPageBlock from "../components/DetailedStats/DetailedStatsPageBlock"
import gamesPerStat from "../js/gamesPerStat"
//import { object } from "prop-types"

class DetailedStatsPage extends React.Component {

  render() {

    // All Time Stats
    const mAllTimeStats = this.props.data.allSheetMessiAllTimeStats.edges.filter(d => d.node.competition === "All Time Career")
    const rAllTimeStats = this.props.data.allSheetRonaldoAllTimeStats.edges.filter(d => d.node.competition === "All Time Career")

    const mAllTimeApps = parseFloat(mAllTimeStats.map(d => d.node.apps))
    const rAllTimeApps = parseFloat(rAllTimeStats.map(d => d.node.apps))

    // let [{
    //   apps: mAllTimeApps,
    //   goals: mAllTimeGoals,
    //   assists: mAllTimeAssists
    // }] = mAllTimeStats.map(d => d.node)

    const mAllTimeGoals = parseFloat(mAllTimeStats.map(d => d.node.goals))
    const rAllTimeGoals = parseFloat(rAllTimeStats.map(d => d.node.goals))
    const mAllTimeAssists = parseFloat(mAllTimeStats.map(d => d.node.assists))
    const rAllTimeAssists = parseFloat(rAllTimeStats.map(d => d.node.assists))

    const mAllTimeOutside = parseFloat(mAllTimeStats.map(d => d.node.outsideBox))
    const rAllTimeOutside = parseFloat(rAllTimeStats.map(d => d.node.outsideBox))

    const mMinsPerGoalCont = parseFloat(mAllTimeStats.map(d => d.node.minsPerGoalContribution))
    const rMinsPerGoalCont = parseFloat(rAllTimeStats.map(d => d.node.minsPerGoalContribution))

    const mFreeKicks = parseFloat(mAllTimeStats.map(d => d.node.freeKicks))
    const rFreeKicks = parseFloat(rAllTimeStats.map(d => d.node.freeKicks))

    const mPens = parseFloat(mAllTimeStats.map(d => d.node.pens))
    const rPens = parseFloat(rAllTimeStats.map(d => d.node.pens))
    const mPensMissed = parseFloat(mAllTimeStats.map(d => d.node.pensMissed))
    const rPensMissed = parseFloat(rAllTimeStats.map(d => d.node.pensMissed))
    const mPenConversion = parseFloat(parseFloat(mPens) * ( 100 / (parseFloat(mPens) + parseFloat(mPensMissed) ))).toFixed(1)
    const rPenConversion = parseFloat(parseFloat(rPens) * ( 100 / (parseFloat(rPens) + parseFloat(rPensMissed) ))).toFixed(1)

    const mHatTricks = parseFloat(mAllTimeStats.map(d => d.node.hatTricks))
    const rHatTricks = parseFloat(rAllTimeStats.map(d => d.node.hatTricks))


    // All Time Stats League + UCL Only
    const mAllTimeLeagueUCLStats = this.props.data.allSheetMessiAllTimeStats.edges.filter(d => d.node.competition === "All Time League and Champions League")
    const rAllTimeLeagueUCLStats = this.props.data.allSheetRonaldoAllTimeStats.edges.filter(d => d.node.competition === "All Time League and Champions League")

    const mAllTimeLeagueUCLMins = parseFloat(mAllTimeLeagueUCLStats.map(d => d.node.minsPlayed))
    const rAllTimeLeagueUCLMins = parseFloat(rAllTimeLeagueUCLStats.map(d => d.node.minsPlayed))

    const mDribbles = parseFloat(mAllTimeLeagueUCLStats.map(d => d.node.successfulDribbles))
    const rDribbles = parseFloat(rAllTimeLeagueUCLStats.map(d => d.node.successfulDribbles))

    // Stats since 09/10 in League + UCL only (where we have data for performance stats)
    const mAllPerfStats = this.props.data.allSheetMessiAllTimeStats.edges.filter(d => d.node.competition === "League and Europe Since 09")
    const rAllPerfStats = this.props.data.allSheetRonaldoAllTimeStats.edges.filter(d => d.node.competition === "League and Europe Since 09")

    const mPerfApps = parseFloat(mAllPerfStats.map(d => d.node.apps))
    const rPerfApps = parseFloat(rAllPerfStats.map(d => d.node.apps))

    const mPerfMins = parseFloat(mAllPerfStats.map(d => d.node.minsPlayed))
    const rPerfMins = parseFloat(rAllPerfStats.map(d => d.node.minsPlayed))

    const mKeyPasses = parseFloat(mAllPerfStats.map(d => d.node.keyPasses))
    const rKeyPasses = parseFloat(rAllPerfStats.map(d => d.node.keyPasses))

    const mThroughballs = parseFloat(mAllPerfStats.map(d => d.node.throughballs))
    const rThroughballs = parseFloat(rAllPerfStats.map(d => d.node.throughballs))

    const mShots = parseFloat(mAllPerfStats.map(d => d.node.shots))
    const rShots = parseFloat(rAllPerfStats.map(d => d.node.shots))
    const mShotsPerGoal = parseFloat(mAllPerfStats.map(d => d.node.shotsPerGoal))
    const rShotsPerGoal = parseFloat(rAllPerfStats.map(d => d.node.shotsPerGoal))

    const mAerialDuels = parseFloat(mAllPerfStats.map(d => d.node.aerialDuels))
    const rAerialDuels = parseFloat(rAllPerfStats.map(d => d.node.aerialDuels))

    const mAvgRating = parseFloat(mAllPerfStats.map(d => d.node.avgRating))
    const rAvgRating = parseFloat(rAllPerfStats.map(d => d.node.avgRating))

    const mAllLeagueUCLRatings = this.props.data.allSheetMessiClubStats.edges.filter(d => d.node.competition !== "All Competitions").map(d => d.node.avgRating ? d.node.avgRating : '').filter(Boolean)
    const mAllLeagueUCLRatingsOver8 = mAllLeagueUCLRatings.map(d => d >= 8).filter(Boolean)

    const rAllLeagueUCLRatings = this.props.data.allSheetRonaldoClubStats.edges.filter(d => d.node.competition !== "All Competitions").map(d => d.node.avgRating ? d.node.avgRating : '').filter(Boolean)
    const rAllLeagueUCLRatingsOver8 = rAllLeagueUCLRatings.map(d => d >= 8).filter(Boolean)

    const mMotm = parseFloat(mAllPerfStats.map(d => d.node.motm))
    const rMotm = parseFloat(rAllPerfStats.map(d => d.node.motm))


    // Stats since 14/15 in League (xG stats)
    const mLeagueSince14Stats = this.props.data.allSheetMessiAllTimeStats.edges.filter(d => d.node.competition === "League Since 14")
    const rLeagueSince14Stats = this.props.data.allSheetRonaldoAllTimeStats.edges.filter(d => d.node.competition === "League Since 14")

    const mSince14Goals = parseFloat(mLeagueSince14Stats.map(d => d.node.goals))
    const rSince14Goals = parseFloat(rLeagueSince14Stats.map(d => d.node.goals))
    const mSince14Assists = parseFloat(mLeagueSince14Stats.map(d => d.node.assists))
    const rSince14Assists = parseFloat(rLeagueSince14Stats.map(d => d.node.assists))

    const mSince14XG = parseFloat(mLeagueSince14Stats.map(d => d.node.xg))
    const rSince14XG = parseFloat(rLeagueSince14Stats.map(d => d.node.xg))
    const mSince14XA = parseFloat(mLeagueSince14Stats.map(d => d.node.xa))
    const rSince14XA = parseFloat(rLeagueSince14Stats.map(d => d.node.xa))


    // Stats since 10/11 in League (BCC stats)
    const mSince10Stats = this.props.data.allSheetMessiAllTimeStats.edges.filter(d => d.node.competition === "League and Europe Since 10")
    const rSince10Stats = this.props.data.allSheetRonaldoAllTimeStats.edges.filter(d => d.node.competition === "League and Europe Since 10")

    const mSince10Mins = parseFloat(mSince10Stats.map(d => d.node.minsPlayed))
    const rSince10Mins = parseFloat(rSince10Stats.map(d => d.node.minsPlayed))

    const mBCC = parseFloat(mSince10Stats.map(d => d.node.bigChancesCreated))
    const rBCC = parseFloat(rSince10Stats.map(d => d.node.bigChancesCreated))


    // El Clasico Stats
    const mClasicoStats = this.props.data.allSheetMessiAllTimeStats.edges.filter(d => d.node.competition === "El Clasico")
    const rClasicoStats = this.props.data.allSheetRonaldoAllTimeStats.edges.filter(d => d.node.competition === "El Clasico")

    const mClasicoMinsPerGoalCont = parseFloat(mClasicoStats.map(d => d.node.minsPerGoalContribution))
    const rClasicoMinsPerGoalCont = parseFloat(rClasicoStats.map(d => d.node.minsPerGoalContribution))

    const mClasicoGoals = parseFloat(mClasicoStats.map(d => d.node.goals))
    const rClasicoGoals = parseFloat(rClasicoStats.map(d => d.node.goals))
    const mClasicoAssists = parseFloat(mClasicoStats.map(d => d.node.assists))
    const rClasicoAssists = parseFloat(rClasicoStats.map(d => d.node.assists))


    // UCL Stats
    const mUCLStats = this.props.data.allSheetMessiAllTimeStats.edges.filter(d => d.node.competition === "UCL Knockouts")
    const rUCLStats = this.props.data.allSheetRonaldoAllTimeStats.edges.filter(d => d.node.competition === "UCL Knockouts")

    const mUCLMinsPerGoalCont = parseFloat(mUCLStats.map(d => d.node.minsPerGoalContribution))
    const rUCLMinsPerGoalCont = parseFloat(rUCLStats.map(d => d.node.minsPerGoalContribution))

    const mUCLGoals = parseFloat(mUCLStats.map(d => d.node.goals))
    const rUCLGoals = parseFloat(rUCLStats.map(d => d.node.goals))
    const mUCLAssists = parseFloat(mUCLStats.map(d => d.node.assists))
    const rUCLAssists = parseFloat(rUCLStats.map(d => d.node.assists))


    // Finals Stats
    const mFinalsStats = this.props.data.allSheetMessiAllTimeStats.edges.filter(d => d.node.competition === "All Career Finals")
    const rFinalsStats = this.props.data.allSheetRonaldoAllTimeStats.edges.filter(d => d.node.competition === "All Career Finals")

    const mFinalsMinsPerGoalCont = parseFloat(mFinalsStats.map(d => d.node.minsPerGoalContribution))
    const rFinalsMinsPerGoalCont = parseFloat(rFinalsStats.map(d => d.node.minsPerGoalContribution))

    const mFinalsGoals = parseFloat(mFinalsStats.map(d => d.node.goals))
    const rFinalsGoals = parseFloat(rFinalsStats.map(d => d.node.goals))
    const mFinalsAssists = parseFloat(mFinalsStats.map(d => d.node.assists))
    const rFinalsAssists = parseFloat(rFinalsStats.map(d => d.node.assists))


    // All Goals Including Youth
    const mAllPlusYouthStats = this.props.data.allSheetMessiAllTimeStats.edges.filter(d => d.node.competition === "All Goals Including Youth")
    const rAllPlusYouthStats = this.props.data.allSheetRonaldoAllTimeStats.edges.filter(d => d.node.competition === "All Goals Including Youth")

    const mAllPlusYouthGoalsPerGame = parseFloat(mAllPlusYouthStats.map(d => d.node.goalsPerGame))
    const rAllPlusYouthGoalsPerGame = parseFloat(rAllPlusYouthStats.map(d => d.node.goalsPerGame))

    const mAllPlusYouthGoals = parseFloat(mAllPlusYouthStats.map(d => d.node.goals))
    const rAllPlusYouthGoals = parseFloat(rAllPlusYouthStats.map(d => d.node.goals))

    return (

      <Layout>
        <SEO 
          title={"Detailed Performance Stats - Messi vs Ronaldo Dribbles, Key Passes, Shots and more"}
          description={`Goals only tell half the story in the Messi vs Ronaldo rivalry. What about key passes, dribbles and even average match ratings? We provide all of these detailed performance stats and more!`}
          canonicalPath={`/detailed-stats/`}
        />

        <h1><span className="sr-only">Messi vs Ronaldo</span> Detailed Stats</h1>

        

        <div className="flex flex-wrap max-w-2xl mx-auto justify-center p-4 sm:pt-6 lg:pt-12 xl:pt-16 2xl:pt-20">

        <DetailedStatsPageBlock
            title="Goal Contributions"
            subTitle="All Time Career"
            playerTitle="Goals and Assists"
            mMainStat={formatNumComma(mAllTimeGoals+mAllTimeAssists)}
            rMainStat={formatNumComma(rAllTimeGoals+rAllTimeAssists)}
            mainStatWinner={statWinner(mAllTimeGoals+mAllTimeAssists, rAllTimeGoals+rAllTimeAssists)}
            mSubStat={`scores or assists every ${parseInt(mMinsPerGoalCont)} minutes`}
            rSubStat={`scores or assists every ${parseInt(rMinsPerGoalCont)} minutes`}
            subStatWinner={statWinner(mMinsPerGoalCont, rMinsPerGoalCont, 'lowWins')}
            moreBtnText="More Goal Contribution Stats"
            moreBtnLink="/detailed-stats/goal-contributions"
          />

          <DetailedStatsPageBlock
            title="Hat tricks"
            subTitle="All Time Career"
            playerTitle="Hat tricks"
            mMainStat={mHatTricks}
            rMainStat={rHatTricks}
            mainStatWinner={statWinner(mHatTricks, rHatTricks)}
            mSubStat={`scores a hat-trick every ${parseFloat(mAllTimeApps / mHatTricks).toFixed(1)} games`}
            rSubStat={`scores a hat-trick every ${parseFloat(rAllTimeApps / rHatTricks).toFixed(1)} games`}
            subStatWinner={statWinner(mAllTimeApps / mHatTricks, rAllTimeApps / rHatTricks, 'lowWins')}
            moreBtnText="More Hat Trick Stats"
            moreBtnLink="/detailed-stats/hat-tricks"
          />

          <DetailedStatsPageBlock
            title="Penalties"
            subTitle="All Time Career"
            playerTitle="Penalty record"
            mMainStat={mPens}
            rMainStat={rPens}
            mainStatWinner={statWinner(mPens, rPens)}
            mSubStat={`scores ${mPenConversion}% of penalties (${mPens}/${mPens + mPensMissed})`}
            rSubStat={`scores ${rPenConversion}% of penalties (${rPens}/${rPens + rPensMissed})`}
            subStatWinner={statWinner(mPenConversion, rPenConversion)}
            moreBtnText="More Penalty Stats"
            moreBtnLink="/detailed-stats/penalties"
          />

          <DetailedStatsPageBlock
            title="Free Kicks"
            subTitle="All Time Career"
            playerTitle="Free Kick Goals"
            mMainStat={mFreeKicks}
            rMainStat={rFreeKicks}
            mainStatWinner={statWinner(mFreeKicks, rFreeKicks)}
            mSubStat={`scores a free kick every ${parseFloat(mAllTimeApps / mFreeKicks).toFixed(1)} games`}
            rSubStat={`scores a free kick every ${parseFloat(rAllTimeApps / rFreeKicks).toFixed(1)} games`}
            subStatWinner={statWinner(mAllTimeApps / mFreeKicks, rAllTimeApps / rFreeKicks, 'lowWins')}
            moreBtnText="More Free Kick Stats"
            moreBtnLink="/detailed-stats/free-kicks"
          />

          <DetailedStatsPageBlock
            title="Outside the Box Goals *"
            subTitle="All Time Career (*open play)"
            playerTitle="Outside box goals"
            mMainStat={formatNumComma(mAllTimeOutside)}
            rMainStat={formatNumComma(rAllTimeOutside)}
            mainStatWinner={statWinner(mAllTimeOutside, rAllTimeOutside)}
            mSubStat={`scores from outside the box every ${gamesPerStat(mAllTimeOutside, mAllTimeApps)} games`}
            rSubStat={`scores from outside the box every ${gamesPerStat(rAllTimeOutside, rAllTimeApps)} games`}
            subStatWinner={statWinner(gamesPerStat(mAllTimeOutside, mAllTimeApps), gamesPerStat(rAllTimeOutside, rAllTimeApps), 'lowWins')}
            moreBtnText="More Outside Box Stats"
            moreBtnLink="/detailed-stats/outside-box-goals"
          />

          <DetailedStatsPageBlock
            title="Key Passes"
            subTitle="League + UCL Since 09/10"
            playerTitle="Key Passes"
            mMainStat={formatNumComma(mKeyPasses)}
            rMainStat={formatNumComma(rKeyPasses)}
            mainStatWinner={statWinner(mKeyPasses, rKeyPasses)}
            mSubStat={`makes ${parseFloat(mKeyPasses / (mPerfMins / 90)).toFixed(2)} key passes every 90 minutes`}
            rSubStat={`makes ${parseFloat(rKeyPasses / (rPerfMins / 90)).toFixed(2)} key passes every 90 minutes`}
            subStatWinner={statWinner(mKeyPasses / (mPerfMins / 90), rKeyPasses / (rPerfMins / 90))}
            moreBtnText="More Key Pass Stats"
            moreBtnLink="/detailed-stats/key-passes"
          />

          <DetailedStatsPageBlock
            title="Big Chances Created"
            subTitle="League + UCL Since 10/11"
            playerTitle="Big Chances Created"
            mMainStat={formatNumComma(mBCC)}
            rMainStat={formatNumComma(rBCC)}
            mainStatWinner={statWinner(mBCC, rBCC)}
            mSubStat={`makes ${parseFloat(mBCC / (mSince10Mins / 90)).toFixed(2)} big chances created every 90 minutes`}
            rSubStat={`makes ${parseFloat(rBCC / (rSince10Mins / 90)).toFixed(2)} big chances created every 90 minutes`}
            subStatWinner={statWinner(mBCC / (mSince10Mins / 90), rBCC / (rSince10Mins / 90))}
            moreBtnText="More Big Chances Created Stats"
            moreBtnLink="/detailed-stats/big-chances-created"
          />

          <DetailedStatsPageBlock
            title="Accurate Throughballs"
            subTitle="League + UCL Since 09/10"
            playerTitle="Throughballs"
            mMainStat={mThroughballs}
            rMainStat={rThroughballs}
            mainStatWinner={statWinner(mThroughballs, rThroughballs)}
            mSubStat={`makes ${parseFloat(mThroughballs / (mPerfMins / 90)).toFixed(2)} accurate throughballs every 90 minutes`}
            rSubStat={`makes ${parseFloat(rThroughballs / (rPerfMins / 90)).toFixed(2)} accurate throughballs every 90 minutes`}
            subStatWinner={statWinner(mThroughballs / (mPerfMins / 90), rThroughballs / (rPerfMins / 90))}
            moreBtnText="More Throughball Stats"
            moreBtnLink="/detailed-stats/throughballs"
          />

          <DetailedStatsPageBlock
            title="Successful Dribbles"
            subTitle="League + UCL Since 03/04"
            playerTitle="Successful Dribbles"
            mMainStat={formatNumComma(mDribbles)}
            rMainStat={formatNumComma(rDribbles)}
            mainStatWinner={statWinner(mDribbles, rDribbles)}
            mSubStat={`makes ${parseFloat(mDribbles / (mAllTimeLeagueUCLMins / 90)).toFixed(2)} successful dribbles every 90 minutes`}
            rSubStat={`makes ${parseFloat(rDribbles / (rAllTimeLeagueUCLMins / 90)).toFixed(2)} successful dribbles every 90 minutes`}
            subStatWinner={statWinner(mDribbles / (mAllTimeLeagueUCLMins / 90), rDribbles / (rAllTimeLeagueUCLMins / 90))}
            moreBtnText="More Dribbling Stats"
            moreBtnLink="/detailed-stats/successful-dribbles"
          />

          <DetailedStatsPageBlock
            title="Shots"
            subTitle="League + UCL Since 09/10"
            playerTitle="Shot Stats"
            mMainStat={formatNumComma(mShots)}
            rMainStat={formatNumComma(rShots)}
            mainStatWinner={statWinner(mShots, rShots)}
            mSubStat={`scores a goal every ${mShotsPerGoal.toFixed(2)} shots`}
            rSubStat={`scores a goal every ${rShotsPerGoal.toFixed(2)} shots`}
            subStatWinner={statWinner(mShotsPerGoal, rShotsPerGoal, 'lowWins')}
            moreBtnText="More Shooting Stats"
            moreBtnLink="/detailed-stats/shot-conversion"
          />

          <DetailedStatsPageBlock
            title="xG (Expected Goals)"
            subTitle="League Since 14/15"
            playerTitle="xG Stats"
            mMainStat={formatNumComma(mSince14XG)}
            rMainStat={formatNumComma(rSince14XG)}
            mainStatWinner={statWinner(mSince14XG, rSince14XG)}
            mSubStat={`scored ${Math.abs((mSince14XG-mSince14Goals).toFixed(2))} more goals than expected`}
            rSubStat={`scored ${Math.abs((rSince14XG-rSince14Goals).toFixed(2))} more goals than expected`}
            subStatWinner={statWinner((mSince14XG-mSince14Goals), (rSince14XG-rSince14Goals), 'lowWins')}
            moreBtnText="More xG Stats"
            moreBtnLink="/detailed-stats/xg-expected-goals"
          />

          <DetailedStatsPageBlock
            title="xA (Expected Assists)"
            subTitle="League Since 14/15"
            playerTitle="xA Stats"
            mMainStat={formatNumComma(mSince14XA)}
            rMainStat={formatNumComma(rSince14XA)}
            mainStatWinner={statWinner(mSince14XA, rSince14XA)}
            mSubStat={`${Math.abs((mSince14XA-mSince14Assists).toFixed(2))} ${(mSince14XA-mSince14Assists) < 0 ? 'more' : 'fewer'} assists than he "should" have`}
            rSubStat={`${Math.abs((rSince14XA-rSince14Assists).toFixed(2))} ${(rSince14XA-rSince14Assists) < 0 ? 'more' : 'fewer'} assists than he "should" have`}
            subStatWinner={statWinner((mSince14XA-mSince14Assists), (rSince14XA-rSince14Assists))}
            moreBtnText="More xA Stats"
            moreBtnLink="/detailed-stats/xa-expected-assists"
          />

          <DetailedStatsPageBlock
            title="Aerial Duels"
            subTitle="League + UCL Since 09/10"
            playerTitle="Ariel Duels"
            mMainStat={formatNumComma(mAerialDuels)}
            rMainStat={formatNumComma(rAerialDuels)}
            mainStatWinner={statWinner(mAerialDuels, rAerialDuels)}
            mSubStat={`wins ${parseFloat(mAerialDuels / (mPerfMins / 90)).toFixed(2)} aerial duels every 90 minutes`}
            rSubStat={`wins ${parseFloat(rAerialDuels / (rPerfMins / 90)).toFixed(2)} aerial duels every 90 minutes`}
            subStatWinner={statWinner(mShotsPerGoal, rShotsPerGoal)}
            moreBtnText="More Aerial Duel Stats"
            moreBtnLink="/detailed-stats/aerial-duels"
          />

          <DetailedStatsPageBlock
            title="Average Match Rating"
            subTitle="League + UCL Since 09/10"
            playerTitle="Average Match Ratings"
            mMainStat={mAvgRating.toFixed(2)}
            rMainStat={rAvgRating.toFixed(2)}
            mainStatWinner={statWinner(mAvgRating, rAvgRating)}
            mSubStat={`averaged above 8.0 in ${mAllLeagueUCLRatingsOver8.length}/${mAllLeagueUCLRatings.length} competitions`}
            rSubStat={`averaged above 8.0 in ${rAllLeagueUCLRatingsOver8.length}/${rAllLeagueUCLRatings.length} competitions`}
            subStatWinner={statWinner(mAllLeagueUCLRatingsOver8.length, rAllLeagueUCLRatingsOver8.length)}
            moreBtnText="More Match Rating Stats"
            moreBtnLink="/detailed-stats/average-match-ratings"
          />

          <DetailedStatsPageBlock
            title="Man of the Match"
            subTitle="League + UCL Since 09/10"
            playerTitle="Man of the Match Awards"
            mMainStat={mMotm}
            rMainStat={rMotm}
            mainStatWinner={statWinner(mMotm, rMotm)}
            mSubStat={`awarded man of the match in ${((mMotm / mPerfApps) * 100).toFixed(0)}% of matches (${mMotm}/${mPerfApps})`}
            rSubStat={`awarded man of the match in ${((rMotm / rPerfApps) * 100).toFixed(0)}% of matches (${rMotm}/${rPerfApps})`}
            subStatWinner={statWinner((mMotm / mPerfApps) * 100, (rMotm / rPerfApps) * 100)}
            moreBtnText="More Man of the Match Stats"
            moreBtnLink="/detailed-stats/man-of-the-match-awards"
          />

          <DetailedStatsPageBlock
            title="Goals Without Penalties"
            subTitle="All Time Career"
            playerTitle="Goals without penalties"
            mMainStat={formatNumComma(mAllTimeGoals-mPens)}
            rMainStat={formatNumComma(rAllTimeGoals-rPens)}
            mainStatWinner={statWinner(mAllTimeGoals-mPens, rAllTimeGoals-rPens)}
            mSubStat={`${((mPens / mAllTimeGoals) * 100).toFixed(1)}% of goals scored are penalties`}
            rSubStat={`${((rPens / rAllTimeGoals) * 100).toFixed(1)}% of goals scored are penalties`}
            subStatWinner={statWinner(((mPens / mAllTimeGoals) * 100).toFixed(1), ((rPens / rAllTimeGoals) * 100).toFixed(1), 'lowWins')}
            moreBtnText="More Non-penalty Goal Stats"
            moreBtnLink="/detailed-stats/goals-without-penalties"
          />

          <DetailedStatsPageBlock
            title="El Clasico Goals & Assists"
            subTitle="All El Clasico Matches"
            playerTitle="El Clasico Goals and Assists"
            mMainStat={formatNumComma(mClasicoGoals+mClasicoAssists)}
            rMainStat={formatNumComma(rClasicoGoals+rClasicoAssists)}
            mainStatWinner={statWinner(mClasicoGoals+mClasicoAssists, rClasicoGoals+rClasicoAssists)}
            mSubStat={`scores or assists every ${parseInt(mClasicoMinsPerGoalCont)} minutes`}
            rSubStat={`scores or assists every ${parseInt(rClasicoMinsPerGoalCont)} minutes`}
            subStatWinner={statWinner(mClasicoMinsPerGoalCont, rClasicoMinsPerGoalCont, 'lowWins')}
            moreBtnText="More El Clasico Stats"
            moreBtnLink="/club-stats/el-clasico"
          />

          <DetailedStatsPageBlock
            title="UCL Knockout Goals & Assists"
            subTitle="Champions League Knockouts"
            playerTitle="Champions League Knockout Goals and Assists"
            mMainStat={formatNumComma(mUCLGoals+mUCLAssists)}
            rMainStat={formatNumComma(rUCLGoals+rUCLAssists)}
            mainStatWinner={statWinner(mUCLGoals+mUCLAssists, rUCLGoals+rUCLAssists)}
            mSubStat={`scores or assists every ${parseInt(mUCLMinsPerGoalCont)} minutes`}
            rSubStat={`scores or assists every ${parseInt(rUCLMinsPerGoalCont)} minutes`}
            subStatWinner={statWinner(mUCLMinsPerGoalCont, rUCLMinsPerGoalCont, 'lowWins')}
            moreBtnText="More Champions League Stats"
            moreBtnLink="/club-stats/champions-league"
          />

          <DetailedStatsPageBlock
            title="Goals & Assists in Finals"
            subTitle="Club & Country"
            playerTitle="Goals and Assists in finals"
            mMainStat={formatNumComma(mFinalsGoals+mFinalsAssists)}
            rMainStat={formatNumComma(rFinalsGoals+rFinalsAssists)}
            mainStatWinner={statWinner(mFinalsGoals+mFinalsAssists, rFinalsGoals+rFinalsAssists)}
            mSubStat={`scores or assists every ${parseInt(mFinalsMinsPerGoalCont)} minutes`}
            rSubStat={`scores or assists every ${parseInt(rFinalsMinsPerGoalCont)} minutes`}
            subStatWinner={statWinner(mFinalsMinsPerGoalCont, rFinalsMinsPerGoalCont, 'lowWins')}
            moreBtnText="More Finals Stats"
            moreBtnLink="/detailed-stats/record-in-finals"
          />

          <DetailedStatsPageBlock
            title="All Goals Including Youth"
            subTitle="All Time Senior + Youth"
            playerTitle="All senior + youth goals"
            mMainStat={formatNumComma(mAllPlusYouthGoals)}
            rMainStat={formatNumComma(rAllPlusYouthGoals)}
            mainStatWinner={statWinner(mAllPlusYouthGoals, rAllPlusYouthGoals)}
            mSubStat={`scores ${parseFloat(mAllPlusYouthGoalsPerGame).toFixed(2)} goals per game`}
            rSubStat={`scores ${parseFloat(rAllPlusYouthGoalsPerGame).toFixed(2)} goals per game`}
            subStatWinner={statWinner(mAllPlusYouthGoalsPerGame, rAllPlusYouthGoalsPerGame)}
            moreBtnText="More Senior + Youth Stats"
            moreBtnLink="/detailed-stats/all-goals-including-youth"
          />

        </div>

        <LastUpdated type="Stats" />
        
      </Layout>

    )
  }
}

export const query = graphql`
  query {
    allSheetMessiAllTimeStats(filter: {pgKey: {regex: "/detailed-main/"}}) {
      edges {
        node {
          id
          competition
          goals
          assists
          apps
          outsideBox
          minsPerGoal
          minsPerGoalContribution
          goalsPerGame
          minsPlayed
          pens
          pensMissed
          hatTricks
          freeKicks
          shots
          keyPasses
          bigChancesCreated
          successfulDribbles
          throughballs
          aerialDuels
          motm
          avgRating
          shotsPerGoal
          xg
          xgc
          xa
          xac
        }
      }
    }
    allSheetRonaldoAllTimeStats(filter: {pgKey: {regex: "/detailed-main/"}}) {
      edges {
        node {
          id
          competition
          goals
          assists
          apps
          outsideBox
          minsPerGoal
          minsPerGoalContribution
          goalsPerGame
          minsPlayed
          pens
          pensMissed
          hatTricks
          freeKicks
          shots
          keyPasses
          bigChancesCreated
          successfulDribbles
          throughballs
          aerialDuels
          motm
          avgRating
          shotsPerGoal
          xg
          xgc
          xa
          xac
        }
      }
    }
    allSheetMessiClubStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          id
          competition
          avgRating
        }
      }
    }
    allSheetRonaldoClubStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          id
          competition
          avgRating
        }
      }
    }
  }
`

export default DetailedStatsPage
